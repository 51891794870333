import React from "react";
import "./contactinfo.css";

function ContactInfo() {
  return (
    <div className="container-wrapper contact">
      <h1>Contact Information</h1>
      <div className="body-style contactBody">
        
        {/* Left Column: Contact Details */}
        <div className="sub-content">
          <p className="p-style">
            Phone: <br />
            +65 8761 8755 <br />
            +65 8783 0087 <br />
            <br />
            Contact@vibrantemployment.sg <br />
            <br />
            EA License: 24C2146 <br />
            <br />
            UEN 202403443N
          </p>
        </div>

        {/* Right Column: Service Hours */}
        <div className="sub-content">
          <h3 className="h3-style">Service Hours</h3>
          <p className="opening-time">
            Monday to Sunday (Daily) <br />
            By Appointment Only <br />
            Contact Us to Schedule an Appointment
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
