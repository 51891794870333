import React from 'react';
import './FloatingSideButton.css';

const FloatingSideButton = () => {
  return (
    <a href="https://www.facebook.com/profile.php?id=61565669834132" target="_blank" rel="noreferrer noopener" className="floating-button">
    <div className="buttonContainer">
        <img src="./Assets/netmaidLogo.png" alt="NetMaid Logo" className="netmaid-logo" />
    </div>
    </a>
  );
};

export default FloatingSideButton;